export default url => {
  const match = url.match(
    /^(?:(https?\:)\/\/)?(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  )
  return match
    ? {
        href: url,
        protocol: match[1] || null,
        host: match[2] || null,
        hostname: match[3] || null,
        port: match[4] || null,
        pathname: match[5] || null,
        search: match[6] || null,
        hash: match[7] || null,
      }
    : {}
}
