import * as R from "ramda"
import { putToStorage, fetchFromStorage } from "../utils/storage"
import { getLatLng } from "./integrations/aws"

const STORAGE_KEY = "tth:locations"

export default async ({ streetAddress, postcode, city }) => {
  /* First check if exists in local cache & not expired */
  const now = new Date().getTime()
  const cache = fetchFromStorage(STORAGE_KEY, true) || {}

  const dataKey = `${streetAddress} ${postcode} ${city}`

  if (
    R.path([dataKey, lat], cache) &&
    R.path([dataKey, lng], cache) &&
    R.pathOr(0, [dataKey, expires], cache) > now
  ) {
    return {
      lat: R.path([dataKey, lat], cache),
      lng: R.path([dataKey, lng], cache),
    }
  }

  /* If not in local cache, fetch from API and then cache locally */
  const { lat, lng } = await getLatLng({ streetAddress, postcode, city })
  if (lat && lng) {
    cache[dataKey] = {
      lat,
      lng,
      expires: now + 30 * 24 * 60 * 60 * 1000,
    }
    putToStorage(STORAGE_KEY, cache, true)
  }
  return { lat, lng }
}
