import React from "react"

import OpenWithIcon from "@material-ui/icons/OpenWith"
import HeightIcon from "@material-ui/icons/Height"
import BusinessIcon from "@material-ui/icons/Business"
import MenuOpenIcon from "@material-ui/icons/MenuOpen"
import LocationOnIcon from "@material-ui/icons/LocationOn"

import LocalAirportIcon from "@material-ui/icons/LocalAirport"
import TramIcon from "@material-ui/icons/Tram"
import TrainIcon from "@material-ui/icons/Train"
import SubwayIcon from "@material-ui/icons/Subway"
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import constants from "../../theme/constants"
import colors from "../../theme/colors"
import typography from "../../theme/typography"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "15px 0",
  },
  figureContainer: {
    display: "flex",
    margin: "15px 0",
    width: "50%",
  },

  iconContainer: {
    background: colors.blue[2],
    borderRadius: "100%",
    display: "inline-block",
    width: "50px",
    height: "50px",
    marginRight: "1.2rem",
  },
  icon: {
    color: colors.base.white,
    width: "38px",
    height: "38px",
    margin: "6px",
  },
  figures: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: "bold",
  },
}))
const urlReplaceNordicChars = string => {
  return string
    .replace(/ä/g, "a")
    .replace(/Ä/g, "A")
    .replace(/ö/g, "o")
    .replace(/Ö/g, "O")
    .replace(/å/g, "a")
    .replace(/Å/g, "A")
}
const KeyFigures = ({ figures,type, city }) => {
  const classes = useStyles()
  let c;
  c = urlReplaceNordicChars(`/${type}/${city}`).toLowerCase();

  let links = {
    "toimistotila": null,
    "tuotanto": null,
    "liiketila": null,
    "varasto/logistiikka": null
  };

  for(let i = 0; i <= figures.length; i++) {
    if (figures[i] && 
      figures[i].class && 
      figures[i].text && 
      (figures[i].class === 'type')
    ) {
      const types = figures[i].text.split(",");
      for(const t of types) {
        if (t.toLowerCase().trim() in links) {
          let linkType = t.toLowerCase().trim();
          if (linkType === "toimistotila") {
            linkType = "toimistot";
          } else if (linkType === "tuotanto") {
            linkType = "toimitilat";
          } else if (linkType === "liiketila") {
            linkType = "liiketilat";
          } else if (linkType === "varasto/logistiikka") {
            linkType = "varastot";
          }
          links[t.toLowerCase().trim()] = urlReplaceNordicChars(`/${linkType}/${city}`).toLowerCase();
        }
      }
    }
  }
  console.log(links);
  return (
    <Grid
      container
      alignItems="flex-start"
      justify="flex-start"
      className={classes.root}
    >
      {figures.map(figure => (
        figure.class !== "type" ? 
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.figureContainer}
          key={figure.title}
        >
          <Box className={classes.iconContainer}>
            {figure.class === "area" ? (
              <OpenWithIcon className={classes.icon} />
            ) : figure.class === "type" ? (
              <BusinessIcon className={classes.icon} />
            ) : figure.class === "floor" ? (
              <MenuOpenIcon className={classes.icon} />
            ) : figure.class === "city" ? (
              <LocationOnIcon className={classes.icon} />
            ) : figure.class === "airport" ? (
              <LocalAirportIcon className={classes.icon} />
            ) : figure.class === "tram" ? (
              <TramIcon className={classes.icon} />
            ) : figure.class === "train" ? (
              <TrainIcon className={classes.icon} />
            ) : figure.class === "subway" ? (
              <SubwayIcon className={classes.icon} />
            ) : figure.class === "bus" ? (
              <DirectionsBusIcon className={classes.icon} />
            ) : (
              <Box />
            )}
          </Box>
          <Box className={classes.figures}>
          
            {figure.title && (
              <Typography
                className={classes.title}
                variant="body2"
                component="span"
              >
                {figure.title}
              </Typography>
            )}
             
            {figure.text && figure.class === "area" && (
              <Typography variant="body2" component="span">
                {figure.text}&nbsp;m<sup>2</sup>
              </Typography>
            )} 
            {figure.text && figure.class !== "area" && (
              <Typography variant="body2" component="span">
                {figure.text}
              </Typography>
            )}
          </Box>
        </Grid>
      : 
      <Grid
      item
      xs={12}
      sm={6}
      className={classes.figureContainer}
      key={figure.title}
    >
    
      <Box className={classes.iconContainer}>
        {figure.class === "type" ? (
          <BusinessIcon className={classes.icon} />
        ): (
          <Box />
        )}
      </Box>
      <Link to={c} style={{color: 'rgba(0, 0, 0, 0.87)', textDecoration:"none"}}>  <Box className={classes.figures}>
            {figure.title && (
              <Typography
                className={classes.title}
                variant="body2"
                component="span"
              >
                {figure.title}
              </Typography>
            )}
            
           
            {figure.text && figure.class !== "area" && (
              <Typography variant="body2" component="span">
                {figure.text.split(",").map((text, index) => (
                  <span>{index > 0 && ', '}<Link to={links[text.toLowerCase().trim()]} style={{color: 'rgba(0, 0, 0, 0.87)', textDecoration:"none"}}>{text}</Link></span>
                ))}
              </Typography>
            )}
          </Box>
          </Link>
    </Grid>
    ) )}
    </Grid>
  )
}

export default KeyFigures
