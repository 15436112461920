import React from "react"

import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
/*import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';*/

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    /* "&:after" {
      position: absolute;
      bottom: 0;  
      height: 100%;
      width: 100%;
      content: "";
      background: 'linear-gradient(to top, rgba(255,255,255, 1) 20%, rgba(255,255,255, 0) 80%'
      pointer-events: none; /* so the text is still selectable * /
  }*/
    "& p": {
      marginBottom: "1rem",
    },
  },
  headline: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  closedFade: {
    //position: 'abolsute',
    //bottom: '0',
    position: "relative",
    width: "100%",
    height: "10px",
    //height:'100%',
    //boxShadow: '0px -30px 24px 24px rgba(255,255,255,1)',
    //boxShadow: '0px -30px 24px 0px rgba(66,66,66,1)'
  },
  content: {
    "& p": {
      marginBottom: "1em",
    },
  },
  openButton: {
    textAlign: "left",
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& svg": {
      height: "1.5rem",
      width: "2rem",
    },
  },
}))

const PremisesDescription = ({ headline, paragraphs }) => {
  /*const [open, setOpen] = React.useState(false);

  const handleChange = () => {
    setOpen((prev) => !prev);
  };*/

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {headline && (
          <Typography
            className={classes.headline}
            variant="body1"
            component="p"
          >
            {headline}
          </Typography>
        )}
        {paragraphs &&
          paragraphs.map((p, idx) => (
            <Typography key={idx} variant="body1" component="p">
              {p}
            </Typography>
          ))}
      </Box>
    </Box>
  )

  /* This is a WIP text exansion, however at the minute the conent is not complete enough to need this. */
  /*return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Collapse in={open} collapsedHeight={200} className={classes.content}>
            {paragraphs && paragraphs.map((p,idx)=>
              <Typography key={idx} variant="body1" compnent ="p">{p}</Typography>
            )}
        </Collapse>
        <Box className={classes.closedFade}></Box>
      </Box>
      <Box className={classes.openButton} onClick={handleChange}>
        
        <Typography variant="body2">
        {(open) ? "Sulje" : "Lue Lisää"}
        </Typography>
        {(open)?<ExpandLessIcon/>:<ExpandMoreIcon/>}
      </Box>
    </Box>
  );*/
}

export default PremisesDescription
