import React from "react"
import * as R from "ramda"

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import { makeStyles } from "@material-ui/core/styles"
import constants from "../../theme/constants"
import colors from "../../theme/colors"
import typography from "../../theme/typography"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "15px 0",
  },
  header: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  row: {
    marginBottom: ".6rem",
  },
  data: {
    "& a": {
      color: colors.blue[1],
      textDecoration: "none",
    },
  },
}))

const Table = ({ data }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {Object.keys(data).map((key, idx) => {
        return R.path([key], data) ? (
          <Grid
            className={classes.row}
            container
            alignItems="flex-start"
            justify="flex-start"
            key={idx}
          >
            <Grid className={classes.header} item xs={12} sm={6}>
              {key}
            </Grid>
            {((key.toUpperCase() === 'VAPAUTUU') && 
              (R.pathOr("", [key], data).length === 10) && 
              (R.pathOr("", [key], data).split('.').length === 3)) && 
              <Grid
              className={classes.data}
              item
              xs={12}
              sm={6}
              dangerouslySetInnerHTML={{ __html: new Date(R.pathOr("", [key], data).split('.')[2] + '-' + R.pathOr("", [key], data).split('.')[1] + '-' + R.pathOr("", [key], data).split('.')[0]) < new Date() ? 'Vapaa' : R.pathOr("", [key], data) }}
            ></Grid>}
            {!((key.toUpperCase() === 'VAPAUTUU') && 
              (R.pathOr("", [key], data).length === 10) && 
              (R.pathOr("", [key], data).split('.').length === 3)) &&
              <Grid
              className={classes.data}
              item
              xs={12}
              sm={6}
              dangerouslySetInnerHTML={{ __html: R.pathOr("", [key], data) }}
            ></Grid>}
          </Grid>
        ) : null
      })}
    </Box>
  )
}

export default Table
