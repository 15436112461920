import React from "react"
import PropTypes from "prop-types"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import * as R from "ramda"
import S3LazyImage from "../../S3LazyImage"
import getMediaUrl from "../../../api/getMediaUrl"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../../theme/colors"

import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${colors.grey[2]}`,
    borderRadius: "6px",
    marginTop: "2rem",
    marginBottom: "2rem",
    padding: "1rem 1.25rem",
    overflow: "hidden",
  },
  heading: {
    fontSize: "1.5rem !important", // Skipping full response text for testing
    marginBottom: "2rem",
  },
  image: {
    backgroundColor: "none",
    height: "220px",
    width: "140px",
  },
  infoBox: {
    //margin: '2rem auto'
  },
  name: {
    fontSize: "1.2rem !important", // Skipping full response text for testing
  },
  title: {
    fontSize: "0.9rem !important", // Skipping full response text for testing
  },
  contact: {
    fontSize: "0.8rem !important", // Skipping full response text for testing
    display: "flex",
    flexDirection: "column",
    marginTop: "1.75rem",
    whiteSpace: "nowrap",
    "& a": {
      textDecoration: "underline",
      color: colors.base.black,
    },
  },
}))

const Broker = ({ forSale, contactPersons }) => {
  const classes = useStyles()

  console

  return (
    <div className={classes.root}>
      {forSale === "true" ? (
        <Typography className={classes.heading} variant="h6" componet="h3">
          Tämän kohteen myy
        </Typography>
      ) : (
        <Typography className={classes.heading} variant="h6" componet="h3">
          Tämän tilan vuokraa
        </Typography>
      )}
      {contactPersons.map((contactPerson, idx) => (
        <Grid
          key={idx}
          container
          spacing={4}
          alignItems="center"
          className={classes.infoBox}
        >
          <Grid item xs={12} sm={4} md={5}>
            <Box className={classes.image}>
              {R.path(["photo"], contactPerson) && (
                <S3LazyImage
                  placeholderSrc={`${getMediaUrl()}/${R.path(
                    ["photo", "name"],
                    contactPerson
                  )}-sm`}
                  src={`${getMediaUrl()}/${R.path(
                    ["photo", "name"],
                    contactPerson
                  )}-md`}
                  alt={`${R.path(["name"], contactPerson)} kuva`}
                  loading="lazy"
                ></S3LazyImage>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={7}>
            <Typography className={classes.name} variant="h6" componet="span">
              {contactPerson.name}
            </Typography>
            {/* removing this as there are no titles coming from MFiles
              <Typography className={classes.title} variant="body1" componet="span">Myyntijohtaja</Typography>*/}
            <Box className={classes.contact}>
              <a href={`tel:${contactPerson.phone}`}>{contactPerson.phone}</a>
              <a href={`mailto:${contactPerson.email}`}>
                {contactPerson.email}
              </a>
            </Box>
          </Grid>
        </Grid>
      ))}
    </div>
  )
}

export default Broker
