import React, { useState } from "react"

import Link from "../navigation/Link"

import Collapse from "@material-ui/core/Collapse"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import PrintIcon from "@material-ui/icons/Print"
import ShareIcon from "@material-ui/icons/Share"

import TwitterIcon from "@material-ui/icons/Twitter"
import FacebookIcon from "@material-ui/icons/Facebook"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import constants from "../theme/constants"
import colors from "../theme/colors"
import typography from "../theme/typography"

const isClient = typeof window !== "undefined"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  shareCollapseContent: {
    width: "50%",
    minWidth: "290px",
    outline: "none",
    margin: "0.5rem 1rem",
  },
  link: {
    height: "1.2rem",
    margin: "0.5rem 1rem",
    color: colors.grey[2],
    textDecoration: "none",
    textTransform: "uppercase",
    display: "flex",

    "&:hover": {
      color: colors.blue[1],
    },
  },
  icon: {
    height: "1.2rem",
  },
  text: {},
  shareLink: {
    textAlign: "center",
  },
  shareIcon: {
    fontSize: "2rem",
    color: colors.grey[2],
    "&:hover": {
      color: colors.blue[1],
    },
  },
}))

const PageActions = ({ href, address }) => {
  const [open, setOpen] = useState(false)

  const handleChange = () => {
    setOpen(prev => !prev)
  }

  const classes = useStyles()

  const onPrintClick = () => {
    if (isClient) {
      window.print()
    }
    return false
  }

  const url = encodeURI(href)
  const title = encodeURI(`${address} | Newsec`)
  const twitterAuthor = "NewsecFI" // Take form GQL

  const twitterShare = `https://twitter.com/share?url=${url}&text=${title}&via=${twitterAuthor}`
  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  const linkedInShare = `https://www.linkedin.com/shareArticle?url=${url}`
  const emailShare = `mailto:?subject=${title}&body=${url}`

  return (
    <Box>
      <Box className={classes.root}>
        {/* Share dialog */}
        <div className={classes.link} onClick={handleChange}>
          <ShareIcon className={classes.icon} />
          <Typography className={classes.text} variant="body1" component="span">
            Jaa
          </Typography>
        </div>
        {/* Print link */}
        <a className={classes.link} href={href} onClick={onPrintClick}>
          <PrintIcon className={classes.icon} />
          <Typography className={classes.text} variant="body1" component="span">
            Tulosta
          </Typography>
        </a>
      </Box>
      <Collapse in={open}>
        <Box className={classes.shareCollapseContent}>
          <Grid container alignItems="center" justify="flex-start" spacing={3}>
            <Grid item>
              <Box className={classes.sharelink}>
                <Link to={twitterShare} text="jaa twitter:lle">
                  <TwitterIcon className={classes.shareIcon} />
                </Link>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.sharelink}>
                <Link to={facebookShare} text="jaa facebook:lle">
                  <FacebookIcon className={classes.shareIcon} />
                </Link>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.sharelink}>
                <Link to={linkedInShare} text="jaa linkedin:lle">
                  <LinkedInIcon className={classes.shareIcon} />
                </Link>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.sharelink}>
                <Link to={emailShare} text="jaa sähköpostille">
                  <MailOutlineIcon className={classes.shareIcon} />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}

export default PageActions
