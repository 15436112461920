import PropTypes from "prop-types"
import React, { useState } from "react"

/* Content display */
//import ReactPlayer from "react-player"
import Carousel from "nuka-carousel"
import S3LazyImage from "../../S3LazyImage"
import EnlargeModal from "./EnlargeModal"

import Box from "@material-ui/core/Box"

import IconButton from "@material-ui/core/IconButton"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"

import ThreeDRotationIcon from "@material-ui/icons/ThreeDRotation"
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../../theme/colors"

import getMediaUrl from "../../../api/getMediaUrl"

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "2rem",
  },
  itemContainer: {
    background: colors.base.black,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "250px",
    height: "60vw",
    maxHeight: "250px",
    [theme.breakpoints.up("sm")]: {
      height: "450px",
      height: "60vw",
      maxHeight: "450px",
    },
    [theme.breakpoints.up("md")]: {
      height: "600px",
      height: "60vw",
      maxHeight: "600px",
    },
  },
  carouselVideo: {
    width: "100%",
  },
  navigationContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.3)",
    },
  },
  navigationButton: {
    color: colors.base.white,
    background: "none",
    "&:hover": {
      background: "none",
    },
    "& svg": {
      height: "3rem",
      width: "3rem",
    },
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
  },
  thumb: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: colors.grey[1], // placeholder as background image will be used
    width: "90px",
    height: "60px",
    margin: "0.6em 0.6em 0 0",
    border: "none",
    "&.selected": {
      border: `0.2em solid ${colors.grey[2]}`,
      padding: 0,
    },
    "& svg": {
      color: colors.base.white,
    },
  },
}))

const ImageCarousel = ({ slides }) => {
  /* External state control of carousel */
  const [slideIndex, setSlideIndex] = useState(0)

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Carousel
        //className={classes.carousel}
        slideIndex={slideIndex}
        afterSlide={i => setSlideIndex(i)}
        enableKeyboardControls={true}
        wrapAround={true}
        dragging={true}
        swiping={true}
        renderBottomCenterControls={null}
        renderCenterLeftControls={({ previousSlide }) => (
          <Box
            className={classes.navigationContainer}
            aria-label="previous picture"
            onClick={previousSlide}
          >
            <IconButton
              aria-label="previous picture"
              className={classes.navigationButton}
              disableRipple={true}
            >
              <NavigateBeforeIcon />
            </IconButton>
          </Box>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <Box
            className={classes.navigationContainer}
            aria-label="next picture"
            onClick={nextSlide}
          >
            <IconButton
              aria-label="next picture"
              className={classes.navigationButton}
              disableRipple={true}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        )}
        getControlsContainerStyles={key => {
          return {
            height: "100%",
          }
        }}
      >
        {slides.map((slide, idx) => {
          switch (slide.type) {
            /*
            case "youtube":
              return (
                <Box key={`${slide.url}`} className={classes.itemContainer}>
                  <ReactPlayer width="100%" height="100%" url={slide.url} />{" "}
                </Box>
              )
            */
            case "tour":
              return (
                <Box key={`${slide.url}`} className={classes.itemContainer}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={slide.url}
                    frameBorder="0"
                    allowFullScreen
                    allow="vr"
                  ></iframe>
                </Box>
              )
            case "image":
              return (
                <Box key={idx} className={classes.itemContainer}>
                  <S3LazyImage
                    className={classes.carouselImage}
                    placeholderSrc={`${getMediaUrl()}/${slide.imgId}-sm`}
                    src={`${getMediaUrl()}/${slide.imgId}-lg`}
                    loading="lazy"
                    alt={slide.alt}
                    keepAspectRatio={slide.keepAspectRatio}
                  />
                  <Box>
                    <EnlargeModal
                      placeholderSrc={`${getMediaUrl()}/${slide.imgId}-sm`}
                      src={`${getMediaUrl()}/${slide.imgId}-lg`}
                      alt={slide.alt}
                    />
                  </Box>
                </Box>
              )
            default:
              return null
          }
        })}
      </Carousel>
      <Box className={classes.thumbsContainer}>
        {slides.map((slide, idx) => {
          switch (slide.type) {
            case "youtube":
              const youtubeVideoId = slide.url.match(/v=(\w+)/)[1]
              const style = youtubeVideoId
                ? {
                    background: `url(https://img.youtube.com/vi/${youtubeVideoId}/default.jpg) center no-repeat`,
                  }
                : {}
              return (
                <Box
                  key={idx}
                  role="img"
                  alt={`${slide.alt} Thumbnail`}
                  className={`${classes.thumb} ${
                    idx === slideIndex ? "selected" : ""
                  }`}
                  onClick={() => setSlideIndex(idx)}
                  style={style}
                >
                  <PlayCircleFilledIcon />
                </Box>
              )
            case "tour":
              return (
                <Box
                  key={idx}
                  role="img"
                  alt={`${slide.alt} Thumbnail`}
                  className={`${classes.thumb} ${
                    idx === slideIndex ? "selected" : ""
                  }`}
                  onClick={() => setSlideIndex(idx)}
                >
                  <ThreeDRotationIcon />
                </Box>
              )
            /*case "pdf":
                return (
                  <Box key={idx} role="img" alt={`${slide.alt} Thumbnail`} className={`${classes.thumb} ${(idx===slideIndex)? "selected" : ""}`} onClick={()=>setSlideIndex(idx)}>
                    <PictureAsPdfIcon/>
                  </Box>)  */

            case "image":
              const imgThumb = `${getMediaUrl()}/${slide.imgId}-thumb.png`
              return (
                <Box
                  key={idx}
                  role="img"
                  alt={`${slide.alt} Thumbnail`}
                  className={`${classes.thumb} ${
                    idx === slideIndex ? "selected" : ""
                  }`}
                  onClick={() => setSlideIndex(idx)}
                  style={{
                    background: `url(${imgThumb}) center no-repeat`,
                    backgroundSize: "cover",
                  }}
                ></Box>
              )
            default:
              return null
          }
        })}
      </Box>
    </Box>
  )
}

ImageCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      priority: PropTypes.number.isRequired,
      type: PropTypes.oneOf(["image", "youtube", "tour", "pdf"]).isRequired,
      url: PropTypes.string, // For videos, tours and PDFs
      imgId: PropTypes.string, // For images in s3 (assume other parts of the path are constant, can make this more dynamic in the future if needed)
    })
  ),
}

ImageCarousel.defaultProps = {
  slides: [
    /*{
      alt: "Tour",
      priority: 3,
      type: "tour",
      url: "https://my.matterport.com/show/?m=b4XBfDMcnDc&brand=0"
    },
    {
      alt: "Image of Kicthen",
      priority: 1,
      type: "image",
      imgId: "20200420-061943-TTH-4401-3-%7BCD4762EF-33A4-4539-A533-B5C0D3C75246%7D"
    },
    {
      alt: "Video",
      priority: 2,
      type: "youtube",
      url: "https://www.youtube.com/watch?v=beEjMCUuYLY"
    },*/
  ],
}

export default ImageCarousel
