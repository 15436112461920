export default string => {
  return string
    .replace(/ä/g, "a")
    .replace(/Ä/g, "A")
    .replace(/ö/g, "o")
    .replace(/Ö/g, "O")
    .replace(/å/g, "a")
    .replace(/Å/g, "A")
}

export const includesNordicChars = string => {
  return /[äÄöÖåÅ]/g.test(string)
}
