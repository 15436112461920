import React from "react"

import GoogleMapReact from "google-map-react"

import Box from "@material-ui/core/Box"

import { makeStyles } from "@material-ui/core/styles"
import constants from "../../theme/constants"
import colors from "../../theme/colors"
import typography from "../../theme/typography"

import RoomIcon from "@material-ui/icons/Room"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.blue[2],
    height: "300px",
    width: "100%",
  },
  pin: {
    color: colors.base.errorRed,
    display: "inline-flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(-50%, -100%)",
  },
}))

const isClient = typeof window !== "undefined"
const mapsKey = process.env.GATSBY_APP_GOOGLEMAPS_KEY
const defaultZoom = 11

const PremisesLocationMap = ({ lat, lng, text }) => {
  const classes = useStyles()

  const location = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  }

  return (
    <Box className={classes.root}>
      {isClient && mapsKey && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapsKey }}
          center={location}
          defaultZoom={defaultZoom}
        >
          <MarkerComponent lat={location.lat} lng={location.lng} text={text} />
        </GoogleMapReact>
      )}
    </Box>
  )
}

export default PremisesLocationMap

const MarkerComponent = () => {
  const classes = useStyles()
  return (
    <Box>
      <RoomIcon className={classes.pin} />
    </Box>
  )
}
