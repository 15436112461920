import React, { useState } from "react"
import * as R from "ramda"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import CloseIcon from "@material-ui/icons/Close"
import S3LazyImage from "../../S3LazyImage"

import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../../theme/colors"

const useStyles = makeStyles(theme => ({
  overlayContainer: {
    position: "absolute",
    bottom: "10px",
    left: "50%",
    transform: "translate(-50%, 0)",
    [theme.breakpoints.up("sm")]: {
      bottom: "25px",
    },
  },
  overlayCloseContainer: {
    margin: "0 auto",
  },
  overlayButton: {
    background: colors.grey["-"],
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 .6rem",
    cursor: "pointer",
  },
  overlayIcon: {
    height: "1.4rem",
    width: "1.2rem",
    marginRight: ".3rem",
  },
  /*
    overlayCloseContainer: {
        position:'absolute',
        bottom: '25px',
        left: '50%',
        transform: 'translate(-50%, 0)'
    },
    overlayButton: {
        background: colors.grey['-'],
        position: 'relative',
        top: '0',
        left: '0',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 .6rem'

    },
    overlayIcon: {
        height: '1.4rem',
        width: '1.2rem',
        marginRight: '.3rem'
    },*/
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backdrop: {
    background: "rgba(0, 0, 0, 0.8)",
  },
  modalBody: {
    outline: "none",
    margin: "auto",
    margin: "2%",
    width: "96%",
    height: "96%",
  },
  modelImage: {
    maxHeight: "100%",
    objectFit: "contain",
  },
}))

const EnlargeModal = ({ placeholderSrc, src, alt }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
    const dataLayerObject = {
      'event':'venueImageClick',
      'eventAction':'venueImageClicked',
    };

    // Push datalayer object
    if (typeof window !== 'undefined') {
      if(window.dataLayer){
        if(window.dataLayer.length > 0 && !R.equals(window.dataLayer[window.dataLayer.length - 1], dataLayerObject)){
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }else if(window.dataLayer.length === 0){
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }
      }else{
        window.dataLayer = [];
        // eslint-disable-next-line no-undef
        window.dataLayer.push(dataLayerObject);
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.overlayContainer}>
        <Box
          className={classes.overlayButton}
          role="button"
          onClick={handleOpen}
        >
          <FullscreenIcon className={classes.overlayIcon} />
          <Typography variant="body2" component="span">
            ISOT KUVAT
          </Typography>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={`Isot kuvat - ${alt}`}
        aria-describedby={`${alt}`}
        disableBackdropClick={true}
        className={classes.modal}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <Box className={classes.modalBody}>
          <S3LazyImage
            placeholderSrc={placeholderSrc}
            src={src}
            loading="lazy"
            alt={`${alt}`}
            keepAspectRatio={true}
            zoomable={true}
          >
            <Box className={classes.overlayCloseContainer}>
              <Box
                className={classes.overlayButton}
                role="button"
                onClick={handleClose}
              >
                <CloseIcon className={classes.overlayIcon} />
                <Typography variant="body2" component="span">
                  SULJE
                </Typography>
              </Box>
            </Box>
          </S3LazyImage>
        </Box>
      </Modal>
    </Box>
  )
}

export default EnlargeModal
